import React from "react"
import Image from '../../static/left.png'
const Information = () => {
  return (
    <div>
       coming soon
    </div>
  )
}

export default Information
